<div class="floating-buttons" [class.show]="showScroll">

  <!-- Botón soporte WhatsApp -->
  <a [href]="getWhatsAppLink()" 
     target="_blank" 
     class="floating-button wsp-button" 
     aria-label="WhatsApp Support" 
     (click)="playSound()">
    <div class="icon-container">
      <i class="fab fa-whatsapp"></i>
    </div>
    <span class="tooltip-text">{{"Contact us on WhatsApp" | translate}}</span>
  </a>

  <!-- Botón que abre el modal para certificación de QR -->
  <a href="javascript:void(0);" 
     class="floating-button qr-button" 
     aria-label="QR Certification" 
     (click)="openModal()">
    <div class="icon-container">
      <span class="material-icons">qr_code</span>
    </div>
    <span class="tooltip-text">{{"Certify your QR" | translate}}</span>
  </a>

  <!-- Botón para formulario de contacto -->
  <a href="https://hms-tech.odoo.com/contactus" 
     target="_blank" 
     class="floating-button mail-button" 
     aria-label="Contact Form" 
     (click)="playSound()">
    <div class="icon-container">
      <i class="material-icons">mail_outline</i>
    </div>
    <span class="tooltip-text">{{"Contact us via email" | translate}}</span>
  </a>

</div>


<!-- Modal -->
<div class="modal-backdrop" *ngIf="showModal">
  <div class="modal-container">
    <div class="modal-header">
      <h2>{{"Certify QR" | translate}}</h2> 
      <button class="close-button" (click)="closeModal()">×</button>
    </div>
    <div class="modal-body">
      <p class="explanation">
        {{"Please enter the full URL or just the token you want to certify. The token is at the end of the URL you received." | translate}}
      </p>
      
      <label for="domain-select">
        {{ "Select Token Domain"| translate }}
      </label>
      <div class="select-container">
        <select id="domain-select" [(ngModel)]="selectedDomain">
          <option *ngFor="let domain of domains" [value]="domain">{{ domain.toUpperCase() }}</option>
        </select>
      </div>
      
            
      
      <input 
        type="text" 
        [(ngModel)]="inputValue" 
        [placeholder]="'Enter the URL or Token' | translate" 
        required 
        minlength="3" 
        (input)="validateInput(inputField)" 
        #inputField="ngModel" 
      />
      <!-- Mensajes de error -->
      <div class="error-message" *ngIf="inputField.errors?.required">
        {{"The field is required." | translate}}
      </div>
      <div class="error-message" *ngIf="inputField.errors?.minlength">
        {{"The token must be at least 3 characters long." | translate}}
      </div>
      
      <button class="submit-button" [disabled]="inputField.invalid" (click)="processInput()">
        {{"Certify QR" | translate}}
      </button>
    </div>
  </div>
</div>


