import { Component, OnInit } from '@angular/core';
import { AuthenticationV2Service } from 'src/app/sesion/services/authentication-v2.service';

@Component({
  selector: 'app-hms-footer',
  templateUrl: './hms-footer.component.html',
  styleUrls: ['./hms-footer.component.scss']
})
export class HmsFooterComponent implements OnInit {
  isLoggedIn: boolean
  supportNumber: string = '541140494999';

  constructor(private authService: AuthenticationV2Service) { 
    // suscribirse a cambios en el estado de autenticación
    this.authService.authenticationState$.subscribe((state) => {
      this.isLoggedIn = state;
    });
  }

  ngOnInit() {
  }


  getWhatsAppLink(): string {
    const message = encodeURIComponent('Hola, me gustaría saber más sobre los productos y servicios de HMS-Tech. ¿Podrían brindarme más detalles?');
    return `https://api.whatsapp.com/send?phone=${this.supportNumber}&text=${message}`;
  }

}
