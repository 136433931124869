import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Identifiers } from '../../model/person/identifiers';
import { COMPONENT_TAG, TYPE_FORM, TYPE_METHOD, config } from '../../assets/mocks/login';
import { ConfigService } from '../../services/config.service';
import { AdminOptionsHmsIdService } from '../../services/manager/admin-options-hms-id.service';
import { Identifier } from '../../model/person/identifier.model';
import { hmsIdentifiers } from '../../assets/mocks/hmsId';
import { ContextSass } from '../../model/context/contextSass';
import { TokenizerService } from '../../services/tokenizer.service';
import { AdminContextSassService } from '../../services/manager/admin-context-sass.service';
import { LocationService } from '../../services/utils/location.service';
import { RespToken } from '../../model/token/respToken';
import { Subscription, interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { TimeService } from '../../services/utils/time.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { ConfigToken, KEY_IDENTIFIER, KEY_PASS, KEY_REMEMBER_ID, KEY_REMEMBER_LOGIN, KEY_REMEMBER_PASS, KEY_TYPE_IDENTIFIER, VALID_INIT_LOGIN } from '../../assets/config/config-constant.example';
import { PersonSSO } from '../../model/SSO/person/person';
import { ContextManagerService } from '../../services/context-manager.service';
import { Apps } from '../../assets/mocks/infoApp';
import { AuthenticationV2Service } from '../../services/authentication-v2.service';
import { AdminProfileService } from '../../services/manager/admin-profile.service';
import { AdminModalService } from '../../services/manager/adminModal.service';
import { InfoModal } from '../../model/info.modal';
import { AdminRolesService } from '../../services/manager/admin-roles.service';
import { domains } from '../../assets/mocks/domains';
import { DomainService } from '../../services/domain.service';
import { Domain } from '../../model/domain/domain';

@Component({
  selector: 'app-box-login',
  templateUrl: './box-login.component.html',
  styleUrls: ['./box-login.component.scss', '../../assets/style.scss']
})
export class BoxLoginComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('modalRegister') modalRegister: any;
  infoModal: InfoModal = new InfoModal("", "");

  @ViewChild('btnLogin') viewChildLogin: any;
  btnLogin: HTMLInputElement|null;

  @ViewChild('popup') viewChildPopup: any;
  tagPopup: HTMLElement|null;

  @Input() configForm?: any;
  @Input() colorTextComponents?: string;

  loginForm: FormGroup;

  identifiers = Identifiers;
  // esto deberia de recuperarse de un servicio
  readonly identifiersList = [
    { code: Identifiers.EMAIL, value: "Email", readOnly: false, selected: true},
    { code: Identifiers.SALUD_SOFT_ID, value: "Id´s HMS", readOnly: false, selected: false},
    { code: Identifiers.CIVIL_ID, value: "Identidad Civil", readOnly: false, selected: false},
    { code: Identifiers.PHONE, value: "Número de Teléfono", readOnly: true, selected: false}
  ];

  initIdCheck = "check_";
  typeMethod?: string = "email";      // tipo de metodo por default del sitio (email, document, idHms, token, phone)
  inpuIdValid?: boolean = false;
  captchaValid?: boolean = false;
  passValid?: boolean = false;
  userLogged?: boolean = false;
  initialized = false;
  formValid: boolean = false;
  password?: string;
  identifiersHms: any[] = hmsIdentifiers;
  isAfterViewInit: boolean = false;
  tokenContext?: string = "-";
  tokenContextAux?: string = "-";
  updateIdentifierFromLS: boolean = false;              // var aux para no pisar los valores recuperados del LS
  typeMethodLS?: any;
  identifierLS?: any;
  passLS?: string;

  components = COMPONENT_TAG;
  methods = TYPE_METHOD;
  /* configForm = config; */
  supportedProfiles: string[] = [];
  
  optionChecked: any = {};

  // type:string|null = null;                        // datos pasado al componente de saludSoftId
  idType: any = {name: this.identifiers.EMAIL};        // por default mostramos todas las opciones de idsHms
  typeIdHms: string = this.identifiers.HMS_ID;  // tipo de idsHms
  identifier: Identifier = { type: Identifiers.EMAIL, value: {} };      // parametros del identifier
  arrayDomains: Domain[] = domains;
  srcIconDomain?: string;
  nIntervals?: any;
  secondInterval?: number;
  secondsMax?: number;
  observerIntervals?: any;
  subscriptionListener?: Subscription;

  eUpdateExternalId: EventEmitter<any> = new EventEmitter<any>();       // setea el valor del input de documento
  eUpdateDocument: EventEmitter<any> = new EventEmitter<any>();       // setea el valor del input de documento
  eUpdatePass: EventEmitter<any> = new EventEmitter<any>();           // setea el valor del input de contraseña
  eUpdateEmail: EventEmitter<any> = new EventEmitter<any>();          // setea el valor del input de mail
  eUpdatePhone: EventEmitter<any> = new EventEmitter<any>();          // setea el valor del input de telefono
  eSetMethod: EventEmitter<any> = new EventEmitter<any>();            // setea el campo de metodo de identificador
  eSetValueCheck: EventEmitter<any> = new EventEmitter<any>();        // setea el checkbox
  eResetDocument: EventEmitter<any> = new EventEmitter<any>();            // resetea el valor del input de documento
  eResetExternalId: EventEmitter<any> = new EventEmitter<any>();          // resetea el valor del input de contraseña
  eResetEmail: EventEmitter<any> = new EventEmitter<any>();               // resetea el valor del input de mail
  eResetPhone: EventEmitter<any> = new EventEmitter<any>();               // resetea el valor del input de telefono

  @Output() notifyInfo = new EventEmitter<any>();                    // informa los datos del form ingresados
  @Output() notifyLogin = new EventEmitter<any>();                    // informa los datos del login
  @Output() notifyRedirectHome = new EventEmitter<any>();             // informa la redireccion al home

  constructor(
    private cdref: ChangeDetectorRef,
    private authenticationService: AuthenticationV2Service,
    private configService: ConfigService,
    private adminOptionsHmsId: AdminOptionsHmsIdService,
    private tokenizerService: TokenizerService,
    private adminContextSassService: AdminContextSassService,
    private locationService: LocationService,
    private timeService: TimeService,
    private localStorageService: LocalStorageService,
    private adminProfileService: AdminProfileService,
    private adminRolesService: AdminRolesService,
    private adminModalService: AdminModalService,
    private domainService: DomainService
    
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$') // Regex para validar email
      ]),
      password: new FormControl('', Validators.required)
    });
  }

  ngOnDestroy(): void {
    this.tokenContext = undefined;
    this.tokenContextAux = this.tokenContext;
    this.subscriptionListener?.unsubscribe();
  }

  ngAfterViewInit() {
    this.btnLogin = this.viewChildLogin.nativeElement;
    this.isAfterViewInit = true;
    this.checkInitLogin();
    this.updateDataComponentFromLS();
    // Verifico si envio cambio de tipo de metodo por default
    if(!this.optionChecked[KEY_REMEMBER_ID] && !this.optionChecked[KEY_REMEMBER_LOGIN]){
      /* console.log("[BoxLogin.comp] == Envio cambio de tipo de metodo por default!"); */
      this.eSetMethod.emit(this.typeMethod);
    }
    this.updateProfiles();
  }

  ngOnInit(): void {
    this.checkConfigLocal();
    this.initFlagsObserver();
    this.typeMethod = this.configForm?.items[0].default;
    /* console.log("[Login-box] => id por default: ", this.typeMethod); */

    // var aux para no mostrar todas las opciones de hmsId dado q aun no esta el desarrollo
    this.initialized = true;
    this.adminOptionsHmsId.showAll();
    this.cdref.detectChanges();

    this.domainService.dataDomain$.subscribe(
      (resp: any) => {
        /* console.log("[onInit] => cambio de dominio: ", resp); */
        if(resp){
          this.updateIconDomain(resp);
        }
      }
    )
  }

  public redirectHome(){
    /* console.log("[Login-box] => Datos user logueados: ", this.userLogged); */
    this.authenticationService.registerUsersListActive(this.userLogged, this.identifier);
    // Como me acabo de loggear debo actualizar el estado de la variable que controla la sesion
    this.authenticationService.authenticationState$.next(true);
    this.notifyRedirectHome.emit(true);
  }

  public onSubmit(){
    if(this.configForm?.type_form == TYPE_FORM.login){
      this.informlogin();
    }
    // si no es login es un form de busqueda
    else{
      this.reportInfoForm();
    }
  }

  public informlogin(){
    /* console.log("ENTRO AL Login con ", this.identifier); */
    let userLogged = this.authenticationService.isLoggedUser(this.identifier);
    if (!userLogged) {
      this.notifyLogin.emit({identifier: this.identifier, typeMethod: this.idType, password :this.password as string});
    }
    // no hace falta el ELSE ya que en el caso de que pase se le da la posibilidad de redirigirse
  }

  // solo informa datos ingresados al form
  private reportInfoForm(){
    this.notifyInfo.emit({identifier: this.identifier, typeMethod: this.idType, password :this.password});
  }
  
  // ##############################################################################################
  // ########################################## LISTENER ##########################################
  
  /* cambios de tipo de metodo */
  public async changeTypeMethod(data: any){
    /* console.log("[Login.comp] => Cambio de tipo de metodo: ", data); */
    if(this.updateIdentifierFromLS){
      setTimeout(() => {
        this.reportValueIdentifier(this.typeMethodLS, this.identifierLS);
       }, 80);
    }
    else{
      this.identifier.value = {};
      this.inpuIdValid = false;
      this.resetInputIdentifier(data.code);
    }
    this.idType = data;
    this.typeMethod = data.name;
    this.identifier.type = data.code;
    // si tengo el RECORDAR ID, seteo con el flag VALID aux del LS
    if(this.optionChecked[KEY_REMEMBER_ID]){
      this.localStorageService.setItem(KEY_TYPE_IDENTIFIER, this.typeMethod);
      this.localStorageService.setItem(KEY_IDENTIFIER, this.identifier);
    }
    else{
      this.localStorageService.setItem(KEY_TYPE_IDENTIFIER, "");
      this.localStorageService.setItem(KEY_IDENTIFIER, "");
    }
    // en el caso de q se seleccione el tipo qr, generamos el token de contexto
    if(this.typeMethod == TYPE_METHOD.qr.label){
      await this.getTokenContext();
      if(!this.tokenContext){
        return;
      }
      /* let secondsMax = this.timeService.getSecondsTotal(config.token.time_expiration); */
      // qedamos a la escucha de un update de contexto
      this.subscriptionListener = this.observerIntervals.subscribe(
        x => {
            this.tokenizerService.getPayloadTokenCtd(this.tokenContext, true).subscribe(
              (resp: any) => {
                /* console.log("[Login.comp] => Resp de datos del token => ", resp); */
                if(resp && resp.payload && this.adminContextSassService.changedContext(resp.payload.context)){
                  let newContext: ContextSass = resp.payload.context;
                  if(!newContext?.shared_context){
                    console.warn("[Login.comp] => No se pudo recuperar el token de contexto compartido!");
                    return;
                  }
                  let sharedTokenContext = newContext.shared_context[0];
                  /* console.log("[Login.comp] => Contexto compartido recibido: ", newContext.shared_context); */
                  this.tokenizerService.getPayloadTokenCtd(sharedTokenContext, true).subscribe(
                    (resp: any) => {
                      console.warn("[Login.comp] => Datos del contexto de la app: ", resp);
                      /* let contextApp = this.adminContextSassService.parseContext(resp.payload); */
                      let contextApp = resp.payload.context;
                      let userToken = contextApp.users_entered[0].user;
                      /* console.log("[Login.comp] => Datos del usuario de la app loguado: ", userToken); */
                      this.getInfoOperator(userToken);
                      
                      // this.identifier.type = Identifiers.EMAIL;
                      // this.identifier.value = user.emails[0].value;
                      // this.notifyLogin.emit({operator: user, identifier:this.identifier, typeMethod:this.idType, password:undefined});
                      /* this.notifyLogin.emit({operator: newUser, identifier:this.identifier, typeMethod:this.idType, password:undefined}); */
                    }
                  )
                // ############################################################################
                }
                else{
                  console.log("[Login.comp] => NO cambio el contexto!!");
                }
              },
              (err: any) => {
                console.log("[Login.comp] => Ha ocurrido un error al recuperar los datos del token!");
              }
            )
          }
      );
      /* console.log("[Login.comp] => cambio el contexto: ", this.adminContextSassService.changedContext(this.adminContextSassService.getContext())); */
      
    }
    else{   // si no estoy en la opcion de qr nos desubscribimos, dejamos de esperar update de token de contexto
      this.tokenContext = undefined;
      this.subscriptionListener?.unsubscribe();
    }

    // en el caso de q se seleccione el tipo qr, generamos el token de contexto
    // if(this.typeMethod == TYPE_METHOD.qr.label){
    //   await this.getTokenContext();
    //   if(!this.tokenContext){
    //     return;
    //   }
    //   this.listenUpdateContextByReadQr();
    // }
    // else{   // si no estoy en la opcion de qr nos desubscribimos, dejamos de esperar update de token de contexto
    //   this.tokenContext = undefined;
    //   this.subscriptionListener?.unsubscribe();
    // }
    this.checkInitLogin();
  }

  public onChangeInputExternalId(data: any){
    this.setDataIdentifier(data, Identifiers.EXTERNAL_ID);
    this.updateDataIdentifierLS();
    this.checkInitLogin();
  }

  public onChangeEmail(data: any){
    /* console.log("[Login.comp] => Cambio el mail: ", data); */
    this.setDataIdentifier(data, Identifiers.EMAIL);
    this.updateDataIdentifierLS();
    
    // verificamos si el usuario ya se encuentra loggeado por su mail
    if(this.inpuIdValid){
      this.userLogged = this.authenticationService.isLoggedUser(this.identifier);
      /* console.log("[Login.comp] => Usuario entontrado: ", this.userLogged); */
    }

    this.checkInitLogin();
  }

  public onChangeCivilId(data: any){
    /* console.log("[Login-box] => Dato documento cambiado: ", data); */
    this.setDataIdentifier(data, Identifiers.CIVIL_ID);
    this.updateDataIdentifierLS();
    this.checkInitLogin();
  }

  public onChangePhone(data: any){
    /* console.log("[Login-box] => Dato telefono cambiado: ", data); */
    this.setDataIdentifier(data, Identifiers.PHONE);
    this.updateDataIdentifierLS();
    this.checkInitLogin();
  }

  public onChangeCaptcha(data: any){
    this.captchaValid = data.valid;
    /* console.log("[Login-box] => Captcha cambiado: ", data); */
    this.checkInitLogin();
  }

  public onChangePassword(data: any){
    /* console.log("[Login-box] => Dato pass cambiado: ", data); */
    this.password = data.value;
    this.passValid = data.valid;
    if(this.optionChecked[KEY_REMEMBER_PASS]){
      this.localStorageService.setItem(KEY_PASS, this.password);
    }
    else{
      /* console.log("[Login-box] => RESET de la pass del LS!!"); */
      this.localStorageService.setItem(KEY_PASS, "");
    }
    this.checkInitLogin();
  }

  public selectCheck(checked: any, option: any){
    /* console.log("[Login-box] => Tipo de identificador : ", this.typeMethod);
    console.log("[Login-box] => Dato option del check cambiado: ", option); */
    this.optionChecked[option.name] = checked;
    this.localStorageService.setItem(option.name, checked);
    
    switch(option.name){
      case KEY_REMEMBER_ID: {
        this.optionChecked[KEY_REMEMBER_ID] = checked;
        this.localStorageService.setItem(KEY_REMEMBER_ID, checked);
        if(checked){
          // guardamos los datos de la info del tipo de indentificador seleccionado y su valor
          this.localStorageService.setItem(KEY_TYPE_IDENTIFIER, this.typeMethod);
          this.localStorageService.setItem(KEY_IDENTIFIER, this.identifier);
          /* console.log("Identificador actual: ", this.identifier); */
        }
        else{
          this.localStorageService.setItem(KEY_TYPE_IDENTIFIER, "");
          this.localStorageService.setItem(KEY_IDENTIFIER, "");
          if(this.optionChecked[KEY_REMEMBER_LOGIN]){      // solo reportamos en el caso en q este previamente en TRUE
            this.optionChecked[KEY_REMEMBER_LOGIN] = false;
            this.localStorageService.setItem(KEY_REMEMBER_LOGIN, false);
            this.eSetValueCheck.next({checked: false, id: this.initIdCheck+KEY_REMEMBER_LOGIN});
          }
        }
        break;
      }
      case KEY_REMEMBER_PASS: {
        this.optionChecked[KEY_REMEMBER_PASS] = checked;
        this.localStorageService.setItem(KEY_REMEMBER_PASS, checked);
        if(checked){
          !this.password ? this.localStorageService.setItem(KEY_PASS, ""): this.localStorageService.setItem(KEY_PASS, this.password);
        }
        else{
          this.localStorageService.setItem(KEY_PASS, "");
          if(this.optionChecked[KEY_REMEMBER_LOGIN]){      // solo reportamos en el caso en q este previamente en TRUE
            this.optionChecked[KEY_REMEMBER_LOGIN] = false;
            this.localStorageService.setItem(KEY_REMEMBER_LOGIN, false);
            this.eSetValueCheck.next({checked: false, id: this.initIdCheck+KEY_REMEMBER_LOGIN});
          }
        }
        break;
      }
      case KEY_REMEMBER_LOGIN: {
        this.optionChecked[KEY_REMEMBER_LOGIN] = checked;
        this.localStorageService.setItem(KEY_REMEMBER_LOGIN, checked);
        if(checked){
          this.localStorageService.setItem(KEY_REMEMBER_ID, true);
          this.localStorageService.setItem(KEY_REMEMBER_PASS, true);
          this.localStorageService.setItem(KEY_PASS, this.password);
          this.localStorageService.setItem(KEY_TYPE_IDENTIFIER, this.typeMethod);
          this.localStorageService.setItem(KEY_IDENTIFIER, this.identifier);
          if(!this.optionChecked[KEY_REMEMBER_PASS]){
            this.eSetValueCheck.next({checked: true, id: this.initIdCheck+KEY_REMEMBER_PASS});
          }
          if(!this.optionChecked[KEY_REMEMBER_ID]){
            this.eSetValueCheck.next({checked: true, id: this.initIdCheck+KEY_REMEMBER_ID});
          }
        }
        break;
      }
    }
    /* console.log("[Login-box] => Flags actualizados: ", this.optionChecked); */
  }

  
  // actualiza los datos de contexto del site
  // private async updateDataTokenContext(){
  //   let newCoord: any = undefined;    
  //   // si no hay token lo generamos con los datos q corresponden
  //   try{
  //     newCoord = await this.locationService.getCoordsLocation();
  //   }
  //   catch{
  //     console.warn("[Login-box] => No se pudieron obtener las coordenadas geograficas.");
  //   }
  //   /* this.adminContextSassService.setLocationGeneration(newCoord); */   // descomentar cuando se pase el modelo de contexto a INGLES
  //   return this.adminContextSassService.getContext();
  // }

  private initFlagsObserver(){
    this.secondInterval = this.timeService.getSecondsTotal(this.configForm?.token.period_deamon_context) as number;
    this.nIntervals = interval( this.secondInterval * 1000);
    this.secondsMax = this.timeService.getSecondsTotal(this.configForm?.token.time_expiration) as number;
    this.observerIntervals = this.nIntervals.pipe(take(this.secondsMax/ this.secondInterval));
  }

  private async getTokenContext(){
    let dataPayload = this.adminContextSassService.getContext();
    
    if(this.adminContextSassService.expiredToken()){
      /* console.log("[Login-box] => Voy a generar un nuevo token con payload: ", dataPayload); */
      this.tokenContext = await this.getToken(dataPayload, config.token.time_expiration);
      this.tokenContextAux = this.tokenContext;
      /* console.log("[Login-box] => Datos del contexto: ", dataPayload); */
    }
    else{
      let token = this.adminContextSassService.getTokenContext();
      this.tokenContextAux = token;
      this.tokenContext = this.tokenContextAux;
    }
    // si no expiro el token no se modifica
    console.log("[Login-box] => Token de contexto: ", this.tokenContext);
  }

  private getInfoOperator(userToken: string){
    this.tokenizerService.getPayloadTokenCtd(userToken, true).subscribe(
      (resp: any) => {
        if(resp.error){
          // TODO: meter mje de error en modal
          console.warn("[BoxLogin.comp] => Error al leer el token!!!");
          return;
        }
        /* let user: any = resp.payload.user; */
        let user: any = resp.payload;
        this.identifier.type = Identifiers.EMAIL;
        this.identifier.value = user.emails[0].value;
        this.notifyLogin.emit({operator: user, identifier:this.identifier, typeMethod:this.idType, password:undefined});
      },
      (error: any) => {
        // TODO: meter mje de error en modal
        console.warn("[BoxLogin.comp] => Error en el service al leer el token. Error: ", error);
      }
    )
  }

  private async getToken(payload: any, expiration: string){
    let respGetToken: RespToken = await this.tokenizerService.getTokenV2({context: payload}, expiration, Number.parseInt(ConfigToken.LENGTH_LOGIN_DEFAULT), ConfigToken.TYPE_LOGIN_DEFAULT, 50).toPromise();
    /* console.log("[Login-box] => Resp de await: ", respGetToken); */
    this.adminContextSassService.setTimeExpired(respGetToken.expiration_moment);
    return respGetToken.short_token as string;
    /* return respGetToken.long_token as string; */
  }
  
  private checkInitLogin(){
    if(!this.isAfterViewInit){
      /* console.log("[Login-box] => NO paso el afterViewInit"); */
      return;
    }
    /* console.log("[Login-box] => InputValido: ", this.inpuIdValid, " - CaptchaValido: ", this.captchaValid); */
    // Analizamos la validez del form dependiendo del tipo de form
    (this.configForm?.type_form == TYPE_FORM.search) ? this.formValid = this.inpuIdValid as boolean: this.formValid = (this.inpuIdValid && this.captchaValid && this.passValid) as boolean;
    /* this.formValid ? this.btnLogin.disabled = false: this.btnLogin.disabled = true; */
  }

  // Actualizamos flags y recuperamos los datos necesarios
  private checkConfigLocal(){
    // recuperamos el valor de cada check
    let rememberIdLS: any = this.localStorageService.getItem(KEY_REMEMBER_ID);
    if(rememberIdLS && (rememberIdLS.length > 0)){
      rememberIdLS = JSON.parse(rememberIdLS);
      this.optionChecked[KEY_REMEMBER_ID] = rememberIdLS;
    }
    else{
      this.optionChecked[KEY_REMEMBER_ID] = false;
    }
    let rememberPassLS: any = this.localStorageService.getItem(KEY_REMEMBER_PASS);
    if(rememberPassLS && (rememberPassLS.length > 0)){
      rememberPassLS = JSON.parse(rememberPassLS);
      this.optionChecked[KEY_REMEMBER_PASS] = rememberPassLS;
    }
    else{
      this.optionChecked[KEY_REMEMBER_PASS] = false;
    }
    // si esta habilitada la opcion de iniciar automaticamente
    let rememberLoginLS = this.localStorageService.getItem(KEY_REMEMBER_LOGIN);
    if(rememberLoginLS && (rememberLoginLS.length > 0)){
      rememberLoginLS = JSON.parse(rememberLoginLS);
      this.optionChecked[KEY_REMEMBER_LOGIN] = rememberLoginLS;
    }
    else{
      this.optionChecked[KEY_REMEMBER_LOGIN] = false;
    }
    /* console.log("[Login-box] == Flags de checks recuperados LS: ", this.optionChecked); */

    // analizamos que datos recuperar dependiendo de los flags activados
    if(this.optionChecked[KEY_REMEMBER_PASS] || this.optionChecked[KEY_REMEMBER_LOGIN]){
      this.passLS = this.localStorageService.getItem(KEY_PASS);
    }
    if(this.optionChecked[KEY_REMEMBER_ID] || this.optionChecked[KEY_REMEMBER_LOGIN]){
      let typeIdentifierLS: string = this.localStorageService.getItem(KEY_TYPE_IDENTIFIER);
      /* console.log("[Login-box] == Datos ID recuperado: ", this.localStorageService.getItem(KEY_IDENTIFIER)); */
      let identifierLS: any = this.localStorageService.getItem(KEY_IDENTIFIER);
      if(identifierLS && (identifierLS.length > 0)){
        this.identifierLS = JSON.parse(identifierLS);
      }
      this.typeMethodLS = typeIdentifierLS;
      this.updateIdentifierFromLS = true;
    }

    /* console.log("[Login-box] == Datos recuperados => tipoId: ", this.typeMethodLS, " - id: ", this.identifierLS, " - pass: ", this.passLS); */
  }

  // actualizamos los datos de los componentes con los datos recuperados del LS
  private updateDataComponentFromLS(){
    if(this.optionChecked[KEY_REMEMBER_PASS]){
      this.password = this.passLS;
      if(this.passLS && (this.passLS.length > 0)){
        this.eSetValueCheck.next({checked: true, id: this.initIdCheck+KEY_REMEMBER_PASS});
        this.eUpdatePass.next(this.passLS);
        /* console.warn("[Login-box] == Datos recuperados => pass: ", this.passLS); */
      }
    }

    if(this.optionChecked[KEY_REMEMBER_ID]){
      this.identifier = this.identifierLS;
      this.inpuIdValid = this.identifierLS.value.valid;   // asignamos el flag del LS
      this.typeMethod = this.typeMethodLS;
      /* console.warn("[Login-box] == Datos recuperados => id: ", this.identifierLS); */
      this.eSetValueCheck.next({checked: true, id: this.initIdCheck+KEY_REMEMBER_ID});
      this.eSetMethod.next(this.typeMethodLS);
    }

    if(this.optionChecked[KEY_REMEMBER_LOGIN]){
      let iniLoginValid = this.localStorageService.getItem(VALID_INIT_LOGIN);
      /* console.log("Flag login => ", iniLoginValid); */
      
      this.eSetValueCheck.next({checked: true, id: this.initIdCheck+KEY_REMEMBER_LOGIN});
      /* console.warn("[Login-box] == Datos recuperados => id: ", this.identifier, " - pass: ", this.password); */
      // if((this.password?.length > 0) && this.identifier.value ){
      if((this.password?.length > 0) && this.identifier.value && iniLoginValid && iniLoginValid != "false"){
        setTimeout(() => {      // esperamos para darle tiempo a que actualize la url del auth para poder loguearnos ok
          this.informlogin();   // TODO: buscar solucion optima
         }, 40);
      }
      else{
        console.warn("[Login-box] == No se puede iniciar sesion automaticamente porque no se disponen de los datos necesarios.");
      }
    }
  }

  // informa el valor del input segun el tipo de metodo
  private reportValueIdentifier(typeMethod: string, identifier: any){
    /* console.log("[Login-box] => (Reporte update)-> IdentifierLS: ",identifier); */
    switch(typeMethod){
      case TYPE_METHOD.mail.label: {
        if(identifier.value.value && (identifier.value.value.length > 0)){    // informo solo si existe input != vacio
          this.eUpdateEmail.next(identifier.value.value);
        }
        break;
      }
      case TYPE_METHOD.document.label: {
        if(identifier.value.value && (identifier.value.value.length > 0)){    // informo solo si existe input != vacio
          this.eUpdateDocument.next(identifier.value);
        }
        break;
      }
      case TYPE_METHOD.phone.label: {
        if(identifier.value.value && (identifier.value.value.length > 0)){    // informo solo si existe input != vacio
          this.eUpdatePhone.next(identifier.value);
        }
        break;
      }
      case TYPE_METHOD.idExternal.label: {
        if(identifier.value.value && (identifier.value.value.length > 0)){    // informo solo si existe input != vacio
          this.eUpdateExternalId.next(identifier.value.value);
        }
        break;
      }
    }
    this.updateIdentifierFromLS = false;
  }

  // actualizamos los datos del LS
  private updateDataIdentifierLS(){
    /* console.log("[Login-box] == (updateDataIdentifierLS) Hay datos por actualizar => flag: ", this.updateIdentifierFromLS, " - id: ", this.identifier); */
    if(this.optionChecked[KEY_REMEMBER_ID]){
      if(this.updateIdentifierFromLS){
        if((this.identifier.type == this.identifierLS.type)){
          this.localStorageService.setItem(KEY_IDENTIFIER, this.identifierLS);
        }
      }
      else{
        this.localStorageService.setItem(KEY_IDENTIFIER, this.identifier);
      }
    }
    else{
      this.localStorageService.setItem(KEY_IDENTIFIER, "");
    }
    /* console.log("[Login-box] == (updateDataIdentifierLS) - identifierLS: ", this.localStorageService.getItem(KEY_IDENTIFIER)); */
  }
 
  public redirect(){
    this.notifyLogin.emit({identifier: this.identifier, typeMethod: this.idType, password :this.password as string});
  }

  private listenUpdateContextByReadQr(){
      /* let secondsMax = this.timeService.getSecondsTotal(config.token.time_expiration); */
      // qedamos a la escucha de un update de contexto
      this.subscriptionListener = this.observerIntervals.subscribe(
        x => {
            this.tokenizerService.getPayloadTokenCtd(this.tokenContext, true).subscribe(
              (resp: any) => {
                console.log("[Login.comp] => Datos del token => ", resp);
                /* if(resp && resp.payload && this.adminContextSassService.changedContext(resp.payload)){ */
                let context = resp.payload.context;
                if(resp && resp.payload && this.adminContextSassService.changedContext(context)){
                  let newContext: ContextSass = context;
                  console.log("[Login.comp] => Cambio el contexto => ", newContext);
                  if(!newContext.shared_context){
                    console.warn("[Login.comp] => No se pudo recuperar el token de contexto compartido!");
                    return;
                  }
                  let tokenContext = newContext.shared_context[0];
                  this.tokenizerService.getPayloadTokenCtd(tokenContext, true).subscribe(
                    (resp: any) => {
                      console.warn("[Login.comp] => Datos del contexto de la app: ", resp);
                      /* let contextApp = this.adminContextSassService.parseContext(resp.payload); */
                      let contextApp = resp.payload.context;
                      console.log("[Login.comp] => Contexto parseado: ", contextApp);
                      let user = contextApp.users_entered[0].user;
                      // TODO aca debo parsear el modelo del usuario al viejo para el caso de credencial deigital
                      let newUser = new PersonSSO();
                      newUser.parse(user);
                      /* console.log("[Login.comp] => Datos del usuario de la app loguado: ", newUser); */
                      this.identifier.type = Identifiers.EMAIL;
                      this.identifier.value = user.emails[0].value;
                      /* this.notifyLogin.emit({operator: user, identifier:this.identifier, typeMethod:this.idType, password:undefined}); */
                      this.notifyLogin.emit({operator: newUser, identifier:this.identifier, typeMethod:this.idType, password:undefined});
                    }
                  )
                // ############################################################################
                }
                else{
                  console.log("[Login.comp] => NO cambio el contexto!!");
                }
              },
              (err: any) => {
                console.log("[Login.comp] => Ha ocurrido un error al recuperar los datos del token!");
              }
            )
          }
      );
  }
  
  // ######################################## para test rapido ########################################
  public loginHardcode(){
    this.identifier.type = Identifiers.EMAIL;
    this.identifier.value = {value: "atorrico@hms-tech.com", status: null, tags: null};
    this.idType = {name: "Email", label: "Email", code: "emails", item_type: "input_mail", placeholder: ""};
    this.password = "abc123B";
    this.informlogin();
  }

  public async updateProfiles(){
    let dataApp: any = Apps.find((data: any) => data.app == this.configService.getApp());
    /* console.log("[updateProfiles] => Datos de la app: ", dataApp); */
    let uniqueProfiles: string[] = [];
    if(dataApp){
      this.adminRolesService.getProfilesByRoles(dataApp.roles)
      .then((resp: any) => {
        /* console.log("[updateProfiles] => Perfiles admitidos por la app: ", resp); */
        if(resp){
          resp as any[];
          resp.forEach((element: string) => {
            if(!uniqueProfiles.includes(element.toUpperCase())){
              uniqueProfiles.push(element.toUpperCase());
            }
          });
          /* console.log("[Login-box] => Perfiles unicos: ", uniqueProfiles); */
          this.supportedProfiles = uniqueProfiles;
          this.adminProfileService.setRequeridProfiles(this.supportedProfiles);
        }
      })
      .catch((err: any) => {
        console.warn("[Login-box] => Resp NOT_OK getProfiles(): ", err);
      })
    }
    else{
      console.warn("[Login-box] => No se encontraron los roles demandados por la app.");
    }
  }

  private setDataIdentifier(dataId: any, typeId: string){
    /* console.log("[Login-box] => Cambio de id: ", dataId); */
    if(!this.updateIdentifierFromLS){
      // los forms mas alla de tomar bien el reset no informan correctamente el estado de validez del mismo, por
      // lo q controlamos q el input no sea vacio para agregar un control mas
      /* (dataId.value.value.length > 0) ? this.inpuIdValid = dataId.valid : this.inpuIdValid = false; */
      /* console.log("[Login-box] => Valor del id: ", dataId.value.value, " - length del id: ", dataId.value.value.length); */
      (dataId.value.value && (dataId.value.value.length > 0)) ? this.inpuIdValid = dataId.valid : this.inpuIdValid = false;
    }
    this.identifier.type = typeId;
    this.identifier.value = dataId.value;
    // guardamos el flag en el identifier para poder preveer errores al guardar datos del id en LS
    this.identifier.value.valid = this.inpuIdValid;
  }

  private updateIconDomain(dataDomain: any){
    /* this.srcIconDomain */
    let dataDomainFound = domains.find((data: any) => dataDomain.domain.toUpperCase() == data.code.toUpperCase());
    /* console.log("[updateIconDomain] => dataDomain encontrado: ", dataDomainFound); */
    this.srcIconDomain = dataDomainFound?.icon;
  }

  private resetInputIdentifier(typeIdentifier: string){
    switch(typeIdentifier){
      case Identifiers.EMAIL:{
        this.eResetEmail.next(true);
        break;
      }
      case Identifiers.CIVIL_ID:{
        this.eResetDocument.next(true);
        break;
      }
      case Identifiers.PHONE:{
        this.eResetPhone.next(true);
        break;
      }
      case Identifiers.EXTERNAL_ID:{
        this.eResetExternalId.next(true);
        break;
      }
    }
  }

  public selectLink(option: any){
    /* console.log("[selectLink] => link seleccionado: ", option); */
    if(option.name == "not_registered"){
       this.adminModalService.showModalGlobal("Registro", "Para registrarse descarguese la app Kirio."); 
      this.infoModal.title = "Registro de usuarios";
      this.infoModal.body = "Para registrarse descarguése la app Kirio de su tienda de aplicaciones";
      this.modalRegister.open();
    }
    if(option.name == "forgot_password"){
      this.infoModal.title = "Administración de cuenta";
      this.infoModal.body = "Para recuperar su contraseña descarguése la app Kirio de su tienda de aplicaciones";
      this.adminModalService.showModalGlobal("Administración de cuenta", "El sitio se encuentra en desarrollo.");
      this.modalRegister.open();
    }
    if(option.name == "problems"){
      this.adminModalService.showModalGlobal("Administración de cuenta", "El sitio se encuentra en desarrollo.");
    }
    
  }
}

