import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CONFIG } from '../assets/config/config.serv.example';
import { BehaviorSubject } from 'rxjs';
import { MicroSite } from '../model/domain/microsite';


@Injectable({
  providedIn: 'root'
})

export class ConfigService{

  /* private configEnv: any; */
  private hostServer: string|null;
  /* private targetServices?: string; */
  private targetServices?: any[];
  private domain: string|null;
  private apiKey: string|null = null;
  private typeEnv: string|null = null;
  private images?: any;
  private microSites: MicroSite[] = [];
  /* hostServer$ = new BehaviorSubject<any>(null); */
  targetServices$ = new BehaviorSubject<any>(null);
  images$ = new BehaviorSubject<any>(null);
  domain$ = new BehaviorSubject<string|undefined>(undefined);

  constructor() {
    // por default tomamos los valores del enviroment
    this.domain = environment.domain;
    this.apiKey = environment.apikey_central;
    // this.domain$.next(this.domain);    // de momento lo cometamos para tomar solo el cambio de dominio del path
 
    this.typeEnv = environment.envDeploy;
    /* console.log("[Domain.serv] => Env seteado: ", this.typeEnv); */
    /* this.configEnv = CONFIG.find(conf => conf.env == env); */
    this.hostServer = null;
   }

  getEnvironment(){
    return environment;
  }

  getTypeEnvironment(){
    return this.typeEnv;
  }

  setTypeEnvironment(env: string){
    this.typeEnv = env;
  }

  getAppName(){
    return environment.appName;
  }

  getApp(){
    return environment.app;
  }

  getAppDomain(){
    return this.domain;
  }

  getImages(): any[]{
    return this.images;
  }

  setImages(images: any[]){
    this.images = images;
    this.images$.next(this.images);
  }

  setAppDomain(newDomain: string){
    this.domain = newDomain;
  }

  getAppVersion(){
    return environment.version;
  }

  getBaseUrlServer(){
    return this.hostServer;
  }

  setBaseUrlServer(url: string){
    this.hostServer = url;
    /* this.hostServer$.next(this.hostServer); */
  }

  setTargetServices(target: any){
    this.targetServices = target;
    this.targetServices$.next(target);
  }

  getCentralServer(){
    return environment.central_server;
  }

  getApiKey(){
    return this.apiKey;
  }

  setApiKey(newApiKey: string){
    this.apiKey = newApiKey;    
  }

  getApiKeyCentralServer(){
    return environment.apikey_central;
  }

  public setMicrositesDomain(microsites: MicroSite[]){
    this.microSites = microsites;
  }

  public getMicrositesDomain(){
    return this.microSites;
  }

  public getBaseUrlMicroservice(name: string){
    let dataMicroservices = this.targetServices?.find((serv: any) => serv.name == name);
    /* console.log("[getUrlMicroservices] => dataMicroservices: ", dataMicroservices); */
    if(!dataMicroservices){
      console.warn("[getUrlMicroservices] => No se pudo recuperara la info de conexion del microservice ", name.toUpperCase());
      return null;
    }
    return this.hostServer + dataMicroservices.addresses.path;
  }

}



