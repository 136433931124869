import { Component, Input, OnInit } from '@angular/core';
import { QRCodeErrorCorrectionLevel } from 'qrcode';

@Component({
  selector: 'app-viewer-qr',
  templateUrl: './viewer-qr.component.html',
  styleUrls: ['./viewer-qr.component.scss']
})
export class ViewerQrComponent implements OnInit {
  @Input() data?: any;      // dato a mostrar
  @Input() size?: number;   // tamaño en px
  /* @Input() errorCorrect: string = 'M'; */
  @Input() errorCorrect: QRCodeErrorCorrectionLevel = 'medium';
  
  // Documentacion de repo: https://www.npmjs.com/package/angularx-qrcode/v/11.0.0

  constructor() { }

  ngOnInit(): void {
  }

}
